.modal-form-box{
	min-width: 600px;
	@media screen and (max-width: 768px) {
		min-width: 100%;
	}
}
@media print {
	
  .avoid-print{
    display: none;
  }
}
.content-table-equivalencias {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  // tbody {
  //   display: grid;
  //   grid-template-columns: auto auto auto;
  //   .row-body {
  //     text-align: center;
  //   }
	// }
	.eqInput{
		background: transparent;
		border-color: lightgray;
		border-radius: 5px;
		border-width: 1px;
		border-style: solid;
	}
  .img-category {
    height: 35px;
    padding:5px;
    padding-left:15px
  }
  td {
    text-align: center;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    width: 50%;
    padding: 5px;
    text-align: center;
  }
}

.warning-button{
  background: red !important;
  color: white !important;
}
.swal-text {
	text-align: center;
}
.swal-footer {
	text-align: center;
}
.swal-button {
	/* background-color: #a5dd72; */
}
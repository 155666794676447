.draggable-dragging {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: red !important;
  cursor: row-resize;
  z-index: 999999 !important;

  * {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  label, p {
    text-align: left;
  } 
}
[class*='multiValue'] {
	width:100%;
	
  /* ... */
}